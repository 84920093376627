import React from 'react';
import {checkData} from "../../../helper";
import {Col, Container, Row} from "react-bootstrap";
import HtmlParser from "react-html-parser";

function ExpertDeveloper({data}) {
    return (
        <section className="tekrevol_dark_bg p-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        <div className="example" data-text="App Developer">
                            <h2>{checkData(data, 'expert_developer_title')}</h2>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        <div>{HtmlParser(checkData(data, 'expert_developer_description'))}</div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default ExpertDeveloper;